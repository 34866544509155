import { IGuest, IGuestExtended } from "src/data/guests/types/guestTypes"
import { TIntegration } from "src/data/integrations/types/integrationTypes"
import MinutIcon from "src/ui/icons/minut-roof.svg?inline"

/**
 * Extend the data in the response with an integration name and logo,
 * if a match is found
 */
export function extendGuestsData(
  integrations: TIntegration[],
  guests: IGuest[]
): IGuestExtended[] {
  const extended = guests.reduce<IGuestExtended[]>((acc, guest) => {
    const source = guest.source

    if (!source || source === "minut" || source === "csv") {
      return [...acc, extendInternallyCreatedGuest(guest, source)]
    }

    const integration = integrations.find(
      (i) => i.integration_identifier === source
    )

    return [
      ...acc,
      {
        ...guest,
        sourceName: integration?.integration_identifier,
        icon: integration?.icon,
        logo: integration?.logo,
      },
    ]
  }, [])

  return extended
}

export function extendInternallyCreatedGuest(
  guest: IGuest,
  source?: "minut" | "csv"
): IGuestExtended {
  return {
    ...guest,
    sourceName: source || "minut",
    icon: MinutIcon,
    logo: undefined,
  }
}
